<template>
	<div class="withdraw_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span>{{ $t('wallet_withdraw.breadcrumb1') }}</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/wallet' }">
				<span>{{ $t('wallet_withdraw.breadcrumb2') }}</span>
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				<span>{{ $t('wallet_withdraw.breadcrumb3') }}</span>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="withdraw">
			<div class="w_title">
				<div class="w_left">
					<div class="name">
						<span>{{ $t('wallet_withdraw.name') }}</span>
						<span
							style="color: #19d079; cursor: pointer"
							@click="router.push('/admin/wallet/history')"
						>
							{{ $t('wallet_withdraw.name2') }}
						</span>
					</div>
					<div class="number">
						<span>{{ userData.usdtBalance }}</span
						>USDT
					</div>
				</div>
				<img src="../../assets/wallet/with_icon1.png" alt="" class="w_right" />
			</div>
			<div class="w_body">
				<el-form :model="state" label-width="auto" label-position="top" size="large">
					<el-form-item :label="$t('wallet_withdraw.label1')">
						<el-select v-model="state.type" disabled>
							<template #label="{ label, value }">
								<img class="w_40" src="../../assets/wallet/wl_icon.png" alt="" />
								<span style="font-weight: bold">{{ value }}</span>
							</template>
							<el-option
								v-for="item in network"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('wallet_withdraw.label2')">
						<el-input v-model="state.address" />
					</el-form-item>
					<el-form-item :label="$t('wallet_withdraw.label3')">
						<el-input v-model="state.money" />
						<div class="money">
							<el-icon><InfoFilled /></el-icon>
							<div class="font">
								{{ $t('wallet_withdraw.money1') }}
								{{ withdrawData.withdrawFee }}USDT，
								{{ $t('wallet_withdraw.money2') }}
								{{ withdrawData.withdrawMin }}USDT，
								{{ $t('wallet_withdraw.money3') }}
							</div>
						</div>
					</el-form-item>
					<el-button class="button" type="primary" @click="onSubmit">{{
						$t('wallet_withdraw.button')
					}}</el-button>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ArrowLeftBold, InfoFilled } from '@element-plus/icons-vue';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { userInfoApi, getWithdrawConfigApi, getAddressApi, withdrawApi } from '@/api/index';

const { locale } = useI18n();
const router = useRouter();

let state = reactive({
	type: 'OP Mainnet-USDT',
	money: '',
});

const network = [
	{
		value: 'OP Mainnet-USDT',
		label: 'OP Mainnet-USDT',
	},
];

const userData = ref({});

// api 请求
userInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			userData.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
const withdrawData = ref({});
// api 请求
getWithdrawConfigApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			withdrawData.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
// const addressData = ref({});
// // api 请求
// getAddressApi()
// 	.then((res) => {
// 		// 关闭加载
// 		if (res.code == 200) {
// 			addressData.value = res.data;
// 		} else {
// 			ElMessage.error(res.msg);
// 		}
// 	})
// 	.catch(() => {
// 		// 关闭加载
// 	});
function onSubmit() {
	if (state.money < withdrawData.value.withdrawMin * 1) {
		if (locale.value == 'en') {
			ElMessage.error('The current balance does not reach the minimum withdrawal amount');
		} else {
			ElMessage.error('当前余额未达到最少提现金额');
		}
	} else if (state.money > userData.value.usdtBalance * 1) {
		if (locale.value == 'en') {
			ElMessage.error('The current withdrawal amount exceeds the account balance');
		} else {
			ElMessage.error('当前提现金额超过账户余额');
		}
	} else {
		// 加载ui
		const loadingInstance1 = ElLoading.service({ fullscreen: true });
		// api 请求
		withdrawApi({
			withdrawAmount: state.money,
			address: state.address,
		})
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
				if (res.code == 200) {
					if (locale.value == 'en') {
						ElMessage.success('Successful withdrawal');
					} else {
						ElMessage.success('提现成功');
					}
				} else {
					ElMessage.error(res.msg);
				}
			})
			.catch(() => {
				// 关闭加载
			});
	}
}
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.withdraw_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				justify-content: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 7px;
				}
			}
			.box:hover {
				cursor: pointer;

				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.withdraw {
			background: #ffffff;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 70px 0 133px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.w_title {
				padding: 25px 37px;
				width: 908px;
				height: 137px;
				border-radius: 7px;
				border: 1px solid #bbbbbb;
				background: #ffffff;
				display: flex;
				justify-content: space-between;
				margin-bottom: 52px;

				.w_left {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.name {
						font-size: 20px;
						color: #666666;
						// margin-bottom: 7px;
						span {
							margin-right: 12px;
						}
					}
					.number {
						font-size: 25px;
						span {
							font-size: 37px;
							color: #333333;
						}
					}
				}
				.w_right {
					width: 100px;
				}
			}
			.w_body {
				display: flex;
				flex-direction: column;
				background: #ffffff;
				width: 908px;
				// height: 601px;
				border-radius: 7px;
				border: 1px solid #bbbbbb;
				padding: 25px 37px 50px;
				.money {
					margin-top: 18px;
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					.el-icon {
						margin-right: 7px;
						color: #ff8900;
					}
					.font {
						color: #ff8900;
						font-size: 19px;
						line-height: 19px;
					}
				}
				.button {
					width: 100%;
					height: 62px;
					background: #333333;
					border-radius: 7px;
					height: 62px;
					background: #333333;
					border-radius: 7px;
					color: #ffffff;
				}
			}
		}

		.w_40 {
			width: 30px;
			height: 30px;
			margin-right: 13px;
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.w_body {
	.el-button {
		// border: 0;
	}
	.el-input__wrapper {
		border: 1px solid #666666;
	}
	.el-select__wrapper {
		border: 1px solid #666666;
	}
}
.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px !important;
	}
}
.el-select__selected-item {
	display: flex !important;
	align-items: center;
}
</style>
