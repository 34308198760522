<template>
	<div class="wallet_box">
		<div class="wallet">
			<div class="title">
				{{ $t('wallet.title') }}
			</div>
			<div class="w_body">
				<div class="w_left">
					<div class="name">
						{{ $t('wallet.name') }}
					</div>
					<div class="number">
						<span>{{ userData.usdtBalance }} </span>USDT
					</div>
				</div>
				<div class="w_right">
					<div class="w_item" @click="router.push('/admin/wallet/topup')">
						<img src="../../assets/wallet/w_icon1.png" alt="" />
						{{ $t('wallet.w_item1') }}
					</div>
					<div class="w_item" @click="router.push('/admin/wallet/withdraw')">
						<img src="../../assets/wallet/w_icon2.png" alt="" />
						{{ $t('wallet.w_item2') }}
					</div>
					<!-- <div class="w_item" @click="router.push('/admin/wallet/history')">
						<img src="../../assets/wallet/w_icon3.png" alt="" />
						划转
					</div> -->
				</div>
			</div>
		</div>
		<div class="history">
			<div class="h_title">
				<span> {{ $t('wallet.h_title') }}</span>
				<span style="color: #19d079; cursor: pointer" @click="router.push('/admin/wallet/history')">
					{{ $t('wallet.h_title2') }}</span
				>
			</div>
			<div class="h_body">
				<div class="empty" v-if="changeList.length == 0">
					{{ $t('wallet.empty') }}
				</div>
				<div class="content" v-else>
					<div class="h_item" v-for="(item, index) in changeList" :key="index">
						<div class="h_item_left">
							<div class="state">
								<span v-if="item.type == 0">{{ $t('wallet.state1') }}</span>
								<span v-else-if="item.type == 1">{{ $t('wallet.state2') }}</span>
								<span v-else-if="item.type == 2">{{ $t('wallet.state3') }}</span>
								<span v-else-if="item.type == 3">{{ $t('wallet.state4') }}</span>
								<span v-else-if="item.type == 4">{{ $t('wallet.state5') }}</span>
								<span v-else>{{ $t('wallet.state6') }}</span>
								<img src="../../assets/wallet/state_icon3.png" alt="" class="state_icon" />
							</div>
							<!-- <div class="number">
								<img src="../../assets/wallet/number_icon1.png" alt="" class="number_icon" />
								{{ item.remark }}
							</div> -->
							<div class="money">
								<span>{{ item.amount }}</span> USDT
							</div>
						</div>
						<div class="h_item_right">
							<img src="../../assets/wallet/h_icon.png" alt="" class="icon" />
							<div class="date">{{ item.createTime }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { userInfoApi, balanceChangeListApi } from '@/api/index';

const router = useRouter();
let state = reactive({});

const userData = ref({});

// api 请求
userInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			userData.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});

const changeList = ref([]);

// 加载ui
const loadingInstance1 = ElLoading.service({ fullscreen: true });
// api 请求
balanceChangeListApi()
	.then((res) => {
		// 关闭加载
		loadingInstance1.close();
		if (res.code == 200) {
			changeList.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
		loadingInstance1.close();
	});
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.wallet_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.wallet {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.w_body {
				height: 202px;
				padding: 0 25px;
				display: flex;
				align-items: center;
				.w_left {
					display: flex;
					flex-direction: column;
					margin-right: 310px;
					.name {
						font-size: 22px;
						color: #4a4a4a;
						margin-bottom: 30px;
					}
					.number {
						font-size: 30px;
						span {
							font-size: 45px;
							color: #333333;
						}
					}
				}
				.w_right {
					display: flex;
					align-items: center;
					.w_item {
						display: flex;
						flex-direction: column;
						margin-right: 68px;
						font-size: 25px;
						align-items: center;
						color: #333333;
						font-weight: 600;
						img {
							width: 70px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.history {
			width: 100%;
			display: flex;
			flex-direction: column;
			background: #ffffff;
			width: 100%;
			min-height: 305px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.h_title {
				padding: 25px 37px 25px 25px;
				width: 100%;
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.h_body {
				display: flex;
				width: 100%;
				.empty {
					width: 100%;
					height: 220px;
					display: flex;
					justify-content: center;
					margin-top: 58px;
					font-size: 17px;
					color: #bbbbbb;
				}
				.content {
					width: 100%;
					min-height: 220px;
					padding: 31px 25px;
					display: flex;

					flex-wrap: wrap;
					.h_item {
						display: flex;
						justify-content: space-between;
						// align-items: center;
						width: 510px;
						height: 144px;
						background: #f6f8fc;
						border-radius: 10px;
						padding: 10px 22px;
						margin: 0 50px 19px 0;
						.h_item_left {
							padding-top: 12px;
							display: flex;
							flex-direction: column;
							.state {
								display: flex;
								align-items: center;
								font-size: 24px;
								color: #333333;
								font-weight: 600;
								margin-bottom: 20px;
								img {
									margin-left: 6px;
									width: 22px;
								}
							}
							.number {
								display: flex;
								align-items: center;
								font-size: 17px;
								color: #666666;
								margin: 6px 0;

								img {
									margin-right: 6px;
									width: 20px;
								}
							}
							.money {
								display: flex;
								align-items: flex-end;
								span {
								// 	display: inline-flex;
								// 	align-items: flex-end;
									font-size: 32px;
									font-weight: 600;
									margin-right: 4px;
								}
								font-size: 26px;
								color: #333333;
							}
						}
						.h_item_right {
							display: flex;
							flex-direction: column;
							font-size: 20px;
							color: #666666;
							// justify-content: flex-end;
							align-items: flex-end;
							.date {
								margin-right: 6px;
							}
							img {
								width: 70px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss"></style>
