<template>
	<div class="history_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span>{{ $t('wallet_history.breadcrumb1') }}</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/wallet' }">
				{{ $t('wallet_history.breadcrumb2') }}
			</el-breadcrumb-item>
			<el-breadcrumb-item>{{ $t('wallet_history.breadcrumb3') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="history">
			<div class="h_title">
				<span>{{ $t('wallet_history.title') }}</span>
			</div>

			<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
				<el-tab-pane :label="$t('wallet_history.title1')" name="1">
					<!-- <el-input
						v-model="input3"
						style="width: 280px; height: 40px"
						placeholder="输入订单号查询"
						class="input-with-select"
					>
						<template #append>
							<el-button :icon="Search" />
						</template>
					</el-input> -->
				</el-tab-pane>
				<el-tab-pane :label="$t('wallet_history.title2')" name="2">
					<!-- <el-input
						v-model="input3"
						style="width: 280px; height: 40px"
						placeholder="输入订单号查询"
						class="input-with-select"
					>
						<template #append>
							<el-button :icon="Search" />
						</template>
					</el-input> -->
				</el-tab-pane>
			</el-tabs>
			<div class="h_body">
				<div class="empty" v-if="false">{{ $t('wallet_history.breadcrumb1') }}</div>
				<el-table class="content" v-else :data="tableData" border style="">
					<!-- <el-table-column prop="date" label="订单号" width="120" /> -->
					<el-table-column prop="status" :label="$t('wallet_history.table1')" width="80">
						<template #default="scope">
							<div v-if="activeName == '1'">
								<span v-if="locale == 'zh'">已到账</span>
								<span v-else>Payment has been received</span>
							</div>
							<div v-else style="display: flex; align-items: center">
								<span v-if="scope.row.status == 0">{{ $t('wallet_history.status1') }}</span>
								<span v-else-if="scope.row.status == 1">{{ $t('wallet_history.status2') }}</span>
								<span v-else-if="scope.row.status == 2">{{ $t('wallet_history.status3') }}</span>
								<span v-else-if="scope.row.status == 3">{{ $t('wallet_history.status4') }}</span>
								<span v-else-if="scope.row.status == 4">{{ $t('wallet_history.status5') }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="withdrawAmount" :label="$t('wallet_history.table2')" width="100">
						<template #default="scope">
							<div style="display: flex; align-items: center">
								<span v-if="activeName == '1'"> {{ scope.row.amount }}{{ scope.row.token }} </span>
								<span v-else style="margin-left: 10px"
									>{{ scope.row.withdrawAmount }}{{ scope.row.symbol }}</span
								>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="withdrawAmount" label="收到金额" width="90" /> -->
					<el-table-column prop="hash" label="TXID" width="540">
						<template #default="scope">
							<div style="display: flex; align-items: center">
								<span v-if="activeName == '1'"> {{ scope.row.transactionHash }}</span>
								<span v-else style="margin-left: 10px"> {{ scope.row.hash }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('wallet_history.table4')" width="350">
						<template #default="scope">
							<div style="display: flex; align-items: center">
								<span v-if="activeName == '1'"> {{ scope.row.addressTo }}</span>
								<span v-else style="margin-left: 10px"> {{ scope.row.address }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column
						v-if="activeName != '1'"
						prop="fee"
						:label="$t('wallet_history.table5')"
						width="136"
					/>
					<el-table-column prop="createTime" :label="$t('wallet_history.table6')" width="180">
						<template #default="scope">
							<div style="display: flex; align-items: center">
								<span v-if="activeName == '1'"> {{ scope.row.mtime }}</span>
								<span v-else style="margin-left: 10px"> {{ scope.row.createTime }}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { ArrowLeftBold, Search } from '@element-plus/icons-vue';
import { withdrawListApi, rechargeListApi } from '@/api/index';

const router = useRouter();
const activeName = ref('1');
const input3 = ref('');

const { locale } = useI18n();
const handleClick = (tab, event) => {
	listApi(tab.props.name);
};
let state = reactive({
	input1: 'GJDKIOQMME',
	input2: 'https://www.coin.com/zh-hant/assets/coin',
	dialogTableVisible: false,
});
const tableData = ref([]);
const listApi = (type) => {
	// 加载ui
	const loadingInstance1 = ElLoading.service({ fullscreen: true });
	if (type == '1') {
		// api 请求
		rechargeListApi()
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
				if (res.code == 200) {
					tableData.value = res.data;
				} else {
					ElMessage.error(res.msg);
				}
			})
			.catch(() => {
				// 关闭加载
				loadingInstance1.close();
			});
	} else {
		// api 请求
		withdrawListApi()
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
				if (res.code == 200) {
					tableData.value = res.data;
				} else {
					ElMessage.error(res.msg);
				}
			})
			.catch(() => {
				// 关闭加载
				loadingInstance1.close();
			});
	}
};
listApi('1');
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.history_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 4px;
				}
			}
			.box:hover {
				cursor: pointer;
				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.history {
			background: #ffffff;
			width: 100%;
			display: flex;
			flex-direction: column;
			width: 100%;
			min-height: 1216px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.h_title {
				padding: 25px 37px 25px 25px;
				width: 100%;
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.h_body {
				display: flex;
				width: 100%;
				background: #ffffff;
				.empty {
					width: 100%;
					height: 220px;
					display: flex;
					justify-content: center;
					margin-top: 58px;
					font-size: 17px;
					color: #bbbbbb;
				}
				.content {
					width: 100%;
					min-height: 220px;
					padding: 31px 25px;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.demo-tabs {
	padding: 15px 25px;
	.el-tabs__nav-wrap:after {
		background: #ffffff;
	}
	.el-tabs__active-bar {
		width: 62px !important;
		margin-left: 7px;
		margin-top: -10px;
		background: #19d079;
		height: 5px;
		bottom: 4px;
	}
	.el-tabs__item.is-active,
	.el-tabs__item:hover {
		color: #333333;
	}
	.el-tabs__item {
		color: #bbbbbb;
	}
}
.el-button {
	border: 0;
}
.el-input__wrapper {
	border: 0;
}
.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px !important;
	}
}
</style>
