<template>
	<div class="topup_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span> {{ $t('wallet_topup.breadcrumb1') }}</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/wallet' }">
				{{ $t('wallet_topup.breadcrumb2') }}
			</el-breadcrumb-item>
			<el-breadcrumb-item> {{ $t('wallet_topup.breadcrumb3') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="topup">
			<!-- <div class="step step1" v-if="state.step == 1">
				<div class="title1">立即购买加密货币</div>
				<div class="title2">即将为您开通当前地区的充值方式</div>
				<div class="step_box">
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active1.png" alt="" />
						<div class="step_font">充值金额</div>
					</div>
					<div class="line"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item2.png" alt="" />
						<span>支付</span>
					</div>
					<div class="line"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item3.png" alt="" />
						<span>支付完成</span>
					</div>
				</div>
				<el-form class="w_body" :model="state" label-width="auto" label-position="top" size="large">
					<el-form-item label="支付方式">
						<el-select v-model="state.type" disabled>
							<template #label="{ label, value }">
								<img class="w_40" src="../../assets/wallet/wl_icon.png" alt="" />
								<span style="font-weight: bold">{{ value }}</span>
							</template>
							<el-option
								v-for="item in network"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="充值金额">
						<el-input v-model="state.money" style="color: #333333">
							<template #suffix>
								<span style="color: #333333"> USDT</span>
							</template>
						</el-input>
					</el-form-item>
					<div class="button_box">
						<el-button
							class="button"
							:disabled="!state.money > 0"
							type="primary"
							@click="onSubmit(1)"
							>下一步</el-button
						>
					</div>
				</el-form>
			</div> -->
			<div class="step step2">
				<!-- <div class="step_box">
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active1.png" alt="" />
						<div class="size_box">
							<span class="step_font">充值金额</span>
							<img class="s_icon" src="../../assets/wallet/step_item_icon.png" alt="" />
						</div>
					</div>
					<div class="line line_active"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active2.png" alt="" />
						<div class="size_box">
							<div class="step_font">支付</div>
							<img class="s_icon" src="../../assets/wallet/step_item_icon.png" alt="" />
						</div>
					</div>
					<div class="line line_active"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active3.png" alt="" />
						<span class="step_font">支付完成</span>
					</div>
				</div> -->
				<el-form class="w_body" :model="state" label-width="auto" label-position="top" size="large">
					<el-form-item>
						<template #label>
							{{ $t('wallet_topup.label1') }}
							<span style="color: #19d079">OP-USDT</span>
							{{ $t('wallet_topup.label2') }}
						</template>
						<el-input v-model="state.address" disabled />
					</el-form-item>
					<el-alert type="warning" show-icon :closable="false">
						{{ $t('wallet_topup.warning1') }} <br />
						{{ $t('wallet_topup.warning2') }}<br />
						{{ $t('wallet_topup.warning3') }}
					</el-alert>

					<div class="qr_box">
						<qrcode-vue class="qrcode" :value="state.address" />
					</div>
					<!-- <div class="qr_font">{{ state.money }}USDT</div>
					<div class="button_box">
						<el-button class="button previous" type="primary" @click="onSubmit(0)"
							>上一步</el-button
						>
						<el-button class="button" type="primary" @click="onSubmit(2)">完成支付</el-button>
					</div> -->
				</el-form>
			</div>
			<!-- <div class="step step3" v-else-if="state.step == 3">
				<div class="step_box">
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active1.png" alt="" />
						<div class="size_box">
							<span class="step_font">充值金额</span>
							<img class="s_icon" src="../../assets/wallet/step_item_icon.png" alt="" />
						</div>
					</div>
					<div class="line line_active"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active2.png" alt="" />
						<div class="size_box">
							<span class="step_font">支付</span>
							<img class="s_icon" src="../../assets/wallet/step_item_icon.png" alt="" />
						</div>
					</div>
					<div class="line line_active"></div>
					<div class="step_item">
						<img src="../../assets/wallet/step_item_active2.png" alt="" />
						<span class="step_font">支付完成</span>
					</div>
				</div>
				<div class="detail_box">
					<div class="detail_item">
						<div class="item_left">币种</div>
						<div class="item_right">USDT</div>
					</div>
					<div class="detail_item">
						<div class="item_left">充值金额</div>
						<div class="item_right">{{ state.money }}</div>
					</div>
					<div class="detail_item">
						<div class="item_left">网络</div>
						<div class="item_right">{{state.type}}</div>
					</div>
					<div class="detail_item">
						<div class="item_left">状态</div>
						<div class="item_right item_right_icon">
							<span>待处理</span>
							<img
								v-if="0 == 0"
								src="../../assets/wallet/state_icon1.png"
								alt=""
								class="state_icon"
							/>
							<img
								v-else-if="0 == 1"
								src="../../assets/wallet/state_icon2.png"
								alt=""
								class="state_icon"
							/>
							<img v-else src="../../assets/wallet/state_icon3.png" alt="" class="state_icon" />
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ArrowLeftBold, InfoFilled } from '@element-plus/icons-vue';
import QrcodeVue from 'qrcode.vue';
import { getAddressApi } from '@/api/index';

const router = useRouter();

let state = reactive({
	type: 'OP Mainnet',
	address: '',
	money: null,
	step: 1,
});

const network = [
	{
		value: 'OP Mainnet',
		label: 'OP Mainnet',
	},
];
const userData = ref({});

// api 请求
getAddressApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			state.address = res.data.address;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
function onSubmit(type) {
	state.step = type + 1;
}
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.topup_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 7px;
				}
			}
			.box:hover {
				cursor: pointer;

				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.topup {
			background: #ffffff;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 50px 0 50px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.step1 {
				.title1 {
					font-weight: 600;
					font-size: 40px;
					color: #333333;
					margin-bottom: 10px;
				}

				.title2 {
					font-weight: 600;
					font-size: 20px;
					color: #bbbbbb;
					margin-bottom: 33px;
				}
				.step_box {
					margin-bottom: 88px;
				}
			}
			.step3 {
				padding-bottom: 190px;
				.detail_box {
					margin-top: 200px;
					display: flex;
					flex-direction: column;
					padding: 20px 30px 0;
					width: 730px;
					height: 308px;
					border-radius: 6px;
					border: 1px solid #e4e4e4;
					.detail_item {
						display: flex;
						justify-content: space-between;
						font-size: 20px;
						margin-bottom: 30px;
						.item_left {
							color: #666666;
						}
						.item_right {
							color: #333333;
						}
						.item_right_icon {
							display: flex;
							align-items: center;
							.state_icon {
								width: 24px;
								margin-left: 8px;
							}
						}
					}
				}
			}
			.step {
				display: flex;
				flex-direction: column;
				align-items: center;
				.step_box {
					display: flex;
					align-items: center;
					margin-bottom: 88px;
					.step_item {
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: 16px;
						color: #bbbbbb;
						img {
							width: 60px;
							margin-bottom: 4px;
						}
						.size_box {
							display: flex;
							flex-direction: row;
							align-items: center;
							.s_icon {
								width: 18px;
								margin-left: 3px;
								margin-top: 3px;
							}
						}
						.step_font {
							font-weight: 600;
							color: #333333;
						}
					}
					.line {
						width: 257px;
						height: 1px;
						border: 1px solid #e4e4e4;
						margin: 0 10px;
					}
					.line_active {
						border: 1px solid #19d079;
					}
				}
				.w_body {
					display: flex;
					flex-direction: column;
					background: #ffffff;
					width: 100%;
					.qr_box {
						width: 220px;
						height: 220px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 8px;
						border: 1px solid #e4e4e4;
						margin: 30px auto 15px;
					}
					.qr_font {
						font-weight: 600;
						font-size: 26px;
						color: #333333;
						margin: 15px auto 30px;
					}
					.button_box {
						display: flex;
						.button {
							width: 100%;
							height: 50px;
							background: #333333;
							border-radius: 6px;
							color: #ffffff;
							outline: none;
							border: 0;
						}
						.previous {
							background: #e4e4e4;
							color: #666666;
						}
					}
				}
			}
		}
		.w_40 {
			width: 40px;
			height: 40px;
			margin-right: 13px;
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.topup_box {
	.el-button {
		// border: 0;
	}
	.el-form-item__label {
		color: #666666;
		font-size: 16px;
	}
	.el-input__wrapper {
		border: 1px solid #666666;
	}
	.el-select__wrapper {
		border: 1px solid #666666;
	}
	.el-alert {
		align-items: flex-start;
		width: 1000px;
		// height: 108px;
		background: #fff9e4;
		border-radius: 4px;
		border: 1px solid #ffd897;
		padding: 15px;
		.el-icon {
			font-size: 20px;
		}
		.el-alert__description {
			color: #333333;
			line-height: 26px;
			font-weight: 600;
			font-size: 16px;
		}
	}

	.qrcode {
		width: 90% !important;
		height: 90% !important;
		border-radius: 6px;
	}
}
.el-select__selected-item {
	display: flex !important;
}

.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px!important;
	}
}
</style>
